//变量
@c-333: #333;
@c-666: #666;
@c-999: #999;
@c-fff: #ffffff;
@c-2ff: #3072FF;
@c-202: #D00202;

.title{
  padding-top: 10vw;
  font-size: 8vw;
  padding-bottom: 5vw;
}
.leftIcon {
  width: 3.33vw;
}
/deep/ .van-cell {
  border-bottom: 1px solid #eee;
  padding-left: 5.33vw;
  padding-right: 5.33vw;
}
.main-btn {
  background: dodgerblue;
  border-color: dodgerblue;
}

@blue: @c-2ff;